button.button, a.button {

  border-radius: 10rem;
  background-color: $cta-color;
  color: #fff;
  font-family: "Poppins";
  border: none;
  font-weight: 700;
  cursor: pointer;
  @include padding-spacing(base, vertical);
  @include padding-spacing(large, horizontal);
  position: relative;
  text-transform: uppercase;
  transition: .2s ease-in-out;
  @include basic-box-shadow();
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    width: 100%;
    padding-top: 100%;
    background-color: $cta-accent;
    transition: .2s ease-in-out;
    transform: scale(0.001) translateY(-45%);
    border-radius: 50rem;
    z-index: -1;
  }
  &:hover {
    transform: scale(1.01);
    @include hovered-box-shadow();
    &::before {
      transform: scale(1.2) translateY(-45%);
    }
  }
  &:disabled {
    opacity: .4;
  }

  &.xs {
    padding: 0.25rem 0.75rem;
  }

  > {
    color: #fff;
    font-family: "Poppins";
    font-weight: 700;
    line-height: .8;
    text-transform: uppercase;
  }

  &.outline {
    border: 3px solid $cta-color;
    color: $cta-color;
    background: transparent;
    &::before {
      background-color: $cta-color;
    }
    > {
      color: $cta-color;
    }

    &:hover {
      background-color: $cta-color;
      color: #fff;
      border: 3px solid $cta-color;
      >, span {
        color: $white;
      }
    }

    &.white {
      border: 3px solid $white;
      color: $white;
      background: transparent;

      > {
        color: $white;
      }

      &:hover {
        background-color: $cta-color;
        color: #fff;
        border: 3px solid $cta-color;

        > {
          color: $white;
        }
      }
    }
  }
}
