@import url('https://fonts.googleapis.com/css?family=Hind+Siliguri:600,700|Poppins:400,700&display=swap');

h1 {
  font-size: map-get($h1-font-size, mobile);
  @include apply-to(md) {
    font-size: map-get($h1-font-size, desktop);
  };
  text-transform: uppercase;
  font-family: "Hind Siliguri", Verdana, sans-serif;
  color: $title-base-color;
  line-height: $large-line-height;
}
h2 {
  font-size: map-get($h2-font-size, mobile);
  @include apply-to(md) {
    font-size: map-get($h2-font-size, desktop);
  };
  font-family: "Hind Siliguri", Verdana, sans-serif;
  color: $title-base-color;
  line-height: $large-line-height;
}

h3 {
  font-size: map-get($h3-font-size, mobile);
  @include apply-to(md) {
    font-size: map-get($h3-font-size, desktop);
  }
  font-family: "Hind Siliguri", Verdana, sans-serif;
  color: $title-base-color;
  line-height: $large-line-height;
}

a {
  color: $primary;
  :hover {
    color: $accent;
  }
}

b {
  font-weight: bold;
}
