//Colors

$primary: #484848;
$accent: #32e5ef;
$cta-color: #f75868;
$cta-accent: #d22269;
$title-base-color: #0D0D0D;
$black: #000;
$white: #fff;

//Spacings

$no-spacing: 0;
$spacing-small: 0.4rem;
$spacing-base: 1rem;
$spacing-medium: 1.5rem;
$spacing-large: 2rem;
$spacing-x-large: 4rem;
$spacing-xx-large: 8rem;

//Containers

$quote-container-width: 60rem;

//Typo

$h1-font-size: (
        mobile: 3rem,
        desktop: 5rem
);
$h2-font-size: (
        mobile: 2.5rem,
        desktop: 3.5rem
);;
$h3-font-size: (
        mobile: 2rem,
        desktop: 2.5rem
);
$base-font-size:(
        mobile: 1.75rem,
        desktop: 2rem
);
$header-font-size: (
        mobile: 1.5rem,
        desktop: 1.75rem
);
$small-line-height: .8;
$base-line-height: 1;
$large-line-height: 1.2;
