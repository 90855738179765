@import "styles/variables";
@import "styles/mixins/index";
@import "styles/ui-components/checkmark";
@import "styles/ui-components/mouse-scroll";
@import "styles/buttons";
@import "styles/spacing";
@import "styles/typography";
@import "styles/header";
@import "styles/hero";
@import "styles/quote-section";
@import "styles/services";
@import "styles/showcase";
@import "styles/meeting-recap";
@import "styles/footer";
@import "styles/popup";
@import "styles/forms";
:root {
  font-size: 62.5%;
  scroll-behavior: smooth;

  body {
    font-family: 'Poppins', Verdana, sans-serif;
    font-size: map-get($base-font-size, mobile);
    @include apply-to(lg) {
      font-size: map-get($base-font-size, desktop);
    }
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    article {
      @include padding-spacing(xlarge, top);
    }

    *,
    &::before,
    &::after {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
    }
  }

  .hidden {
    display: none;
  }
}
