article.showcase {
  @include apply-to(md) {
    @include margin-spacing(large, horizontal);
  }
  h2 {
    text-align: center;
    position: relative;
    margin: 0;
    @include margin-spacing(large, bottom);
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -10px;
      width: 20rem;
      border-radius: 5px;
      height: 4px;
      background-color: $accent;
    }
  }

  .showcase-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  @include apply-to(md) {
    flex-direction: row;

  }
    section {
      position: relative;
      width: -webkit-fill-available;
      padding-top: 50%;
      transition: .3s ease-in-out;

      @include margin-spacing(medium, all);
      @include apply-to(md) {
        padding-top: 16.7%;
        @include margin-spacing(medium, all);
      }

      &:hover {
        .card {
          transform: scale(1.1);
          z-index: 2;
        }
        @include hovered-box-shadow();
      }
      .card {
        transition: .3s ease-in-out;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: 20px;
        overflow: hidden;
        @include apply-to(md) {
          @include margin-spacing(no, horizontal);
        }
        box-shadow: 0px 3px 12px #00000033;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
