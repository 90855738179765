article.meeting-recap {
  @include margin-spacing(xlarge, top);
  position: relative;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("assets/images/home-bg.jpg");
  background-color: #10153A;

  &::before {
     content: "";
     top: -1px;
     left: 0;
     right: 0;
     height: 5rem;
     background: url("assets/images/shape-top.png");
     position: absolute;
     background-size: cover;
   }
  &::after {
    content: "";
    bottom: -1px;
    left: 0;
    right: 0;
    height: 5rem;
    background: url("assets/images/shape-bottom.png");
    position: absolute;
    background-size: cover;
  }

  h2 {
    @include margin-spacing(base, horizontal);
    color: $white;
    text-align: center;
  }

  .meeting-card {
    @include margin-spacing(base, horizontal);
    @include basic-box-shadow();
    border-radius: 20px;
    background-color: white;
    @include margin-spacing(large, bottom);
    @include padding-spacing(base,horizontal);
    @include apply-to(md) {
      @include margin-spacing(large, bottom);
      @include margin-spacing(xlarge, horizontal);
      @include padding-spacing(no,horizontal);
    }
    border: 10px solid rgba($accent, 0.3);

    h3 {
      color: $title-base-color;
      text-align: center;
    }

    .meeting-showcase {

      @include apply-to(md) {
        flex-direction: row;
        align-items: center;
      }
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;

      .meeting-item {
        display: flex;
        position: relative;
        @include apply-to(md) {
          &:not(:last-child) {
            &::after {
              background-color: rgba($primary, 0.4);
              content: "";
              position: absolute;
              right: -2px;
              top: 0;
              bottom: 0;
              height: 7rem;
              margin: auto;
              width: 4px;
              border-radius: 4px;
            }
          }
        }

        @include apply-to(md) {
          @include padding-spacing(large, horizontal);
          width: 33%;
        }
        img {
          @include margin-spacing(large, right);
          width: 7rem;
        }
        p {
          font-weight: bold;
          color: $primary;
        }
      }
    }
  }
  button.button {
    @include margin-spacing(large,bottom);
    h3 {
      text-align: center;
      color: white;
    }
  }
}
