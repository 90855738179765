.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  label {
    color: white;
    @include margin-spacing(base, bottom);
    font-size: map-get($base-font-size, mobile);
    @include apply-to(md) {
      font-size: map-get($base-font-size, desktop);
    }
  }
  input,select {
    width: 100%;
    font-family: 'Poppins', Verdana, sans-serif;
    font-size: map-get($h3-font-size, mobile);
    @include apply-to(md) {
      font-size: map-get($base-font-size, desktop);
      @include padding-spacing(base, all);
    }
    border-radius: 5px;
    border:none;
    background-color: $white;
    @include padding-spacing(medium, all);
    @include margin-spacing(medium, bottom);
    @include basic-box-shadow();
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.7; /* Firefox */
    }
  }
  select {
    appearance: none;
    -webkit-appearance: none;
    height: map-get($h3-font-size, desktop) + 2 * $spacing-medium;
  }
}
