header.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  flex-wrap: wrap;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  @include apply-to(xsm) {
    @include padding-spacing(base, vertical);
    @include padding-spacing(medium, horizontal);
  }
  @include padding-spacing(base, vertical);
  @include padding-spacing(base, horizontal);
  z-index: 100;
  transition: .2s ease-in-out;
  background-color: rgba($white, 0);

  &.scrolled {
    background-color: rgba($white, 1);
    @include basic-box-shadow();
    .burger-button {
      span {
        background-color: $primary;
      }
    }
    nav {

      ul {
        li {
          color: $accent;

          a {
            color: $title-base-color;
          }
        }

        button {
          span {
            color: $cta-color;
          }

          &:hover {
            span {
              color: $white;
            }
          }
        }

      }
    }
  }
  .burger-button {
    background: transparent;
    border: none;
    position: relative;
    width: 3rem;
    height: 3rem;
    @include apply-to(xsm) {
      width: 4rem;
      height: 4rem;
    }

    padding: 0;
    span {
      width: 80%;
      height: .5rem;
      border-radius: 1rem;
      display: block;
      position: absolute;
      transform-origin: center;
      transition-duration: 86ms;
      transition-property: background-color,opacity,transform;
      transition-timing-function: ease-out;
      top: calc(50% - .3rem);
      right: 0;
      background-color: $white;
      &:first-child {
        top: calc(50% - .8rem);
      }

      &:nth-child(2) {
        @include apply-to(xsm) {
          top: calc(50% + .3rem);
        }
        top: calc(50% + .2rem);
      }
    }
    &.active span:first-child {
      -webkit-transform: translateY(.8rem) rotate(45deg);
      transform: translateY(.8rem) rotate(45deg);
    }

    &.active span:nth-child(2) {
      @include apply-to(xsm) {
        -webkit-transform: translateY(-.3rem) rotate(-45deg);
        transform: translateY(-.3rem) rotate(-45deg);
      }
      -webkit-transform: translateY(-.2rem) rotate(-45deg);
      transform: translateY(-.2rem) rotate(-45deg);
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    @include apply-to(xsm) {
      width: 5rem;
      height: 5rem;
    }
    @include apply-to(md) {
      justify-content: start;
      height: max-content;
      width: auto;
    }
    img {
      @include apply-to(md) {
        @include padding-spacing(small, vertical);
      }
      height: 100%;
      width: auto;
    }
  }
  button.button {
    font-size: map-get($header-font-size, mobile);
    @include padding-spacing(small, vertical);
    @include padding-spacing(base, horizontal);
  }
  .mobile-break {
    flex-basis: 100%;
    height: 0;
    @include apply-to(md) {
      flex-basis: unset;
    }
  }
  nav {
    transition: .3s ease-in-out;
    max-height: 0;
    @include apply-to(md) {
      max-height: unset;
    }
    &.opened {
      max-height: 100vh;
    }
    ul {
      display: flex;
      list-style: none;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      padding: 0;
      @include apply-to(md) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      margin: 0;
      li {
        @include apply-to(md) {
          @include padding-spacing(no, top);

        }
        @include padding-spacing(medium, top);
      }
      li,span,button.button {
        text-transform: uppercase;
        font-weight: bold;
        font-size: map-get($header-font-size, mobile) * 1.3;
        @include apply-to(md) {
          font-size: map-get($header-font-size, desktop);
        }
        color: $white;
        cursor: pointer;

        a {
          @include padding-spacing(base, horizontal);
          @include padding-spacing(small, vertical);
          &:hover {
            color: $accent;
          }

          color: $white;
        }
      }
    }
  }
}
