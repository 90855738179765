article.quote {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include padding-spacing(base, horizontal);
  @include apply-to(md) {
    @include padding-spacing(no, horizontal);
  }
  .quote-icon-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    @include margin-spacing(medium, bottom);

    &::after, &::before {
      content: "";
      width: 10rem;
      @include apply-to(md) {
        width: 15rem;
      }
      height: 4px;
      @include margin-spacing(medium, horizontal);
      @include apply-to(md) {
        @include margin-spacing(large, horizontal);
      }
      border-radius: 50px;
      background-color: $black;
      @include basic-box-shadow;
    }

    img.quote-icon {
      width: 5rem;
    }
  }

  p.quote-text {
    @include apply-to(md) {
      max-width: $quote-container-width;
    }
    text-align: center;

    &::after, &::before {
      content: "‘’";
    }
  }

  .quote-author {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include margin-spacing(large, top);


    img {
      width: 10rem;
    }
    p.author {
      @include margin-spacing(small, top);
      @include margin-spacing(no, bottom);
      font-weight: bold;
    }
    p.job {
      margin-top: 0;

      a {
        text-decoration: underline;
      }
    }
  }
}
